import * as React from 'react';
import ReactPlayer from 'react-player';
import { Icon } from 'semantic-ui-react';
import { INITIAL_STATE, reducer, PlayerAction } from './player_reducer';
import { PlayerState, OverlayState } from './player_state';
import PlayerOverlay from './player_overlay';
import PlayerControls from './player_controls';
import { UserCourseModuleOutputDto } from '../../../../models/course/learning_module';
import ExpandableSegment from '../../../../app/components/expandable_segment';
import { useCallback, useEffect, useMemo, useState } from 'react';
//import { useDebounce } from '../../../../app/components/use_debounce';
import { MediaTrackingEvents } from '../../../../models/user/user_lesson_action';

interface LessonVideoPlayerProps {
  module?: UserCourseModuleOutputDto;
  index: number;
  videoKey: string;
  activeVideoIndex: number;
  setActiveVideoIndex: (index: number) => void;
  saveMediaTrackingEvents?: (events: MediaTrackingEvents) => void;
}

/*
interface ResolutionOption {
  file: string;
  label: string;
  default: boolean;
}
*/

const LessonVideoPlayer: React.FC<LessonVideoPlayerProps> = ({ module, index, videoKey, activeVideoIndex, setActiveVideoIndex, saveMediaTrackingEvents }) => {
  const [currentTime, setCurrentTime] = useState<number>(() => {
    // If completedFlag is false, initialize currentTime based on the index
      if (!module?.userModuleQuiz?.completedFlag) {
        return index === 0
          ? module?.userModuleQuiz?.mediaTrackingEventsJson?.v1LastSeekPos ?? 0
          : module?.userModuleQuiz?.mediaTrackingEventsJson?.v2LastSeekPos ?? 0;
      }
      // If completedFlag is true, initialize to 0
      return 0;
    });

  //const debouncedCurrentTime = useDebounce(currentTime, 200);
  const [isPlaying, setIsPlaying] = useState(false);
  //const [isCompleted, setIsCompleted] = useState(false);
  const [lastSeekPos, setLastSeekPos] = useState<number>(0);
  const [hasSeeked, setHasSeeked] = useState(false);
  const [state, dispatch] = React.useReducer<React.Reducer<PlayerState, PlayerAction>>(reducer, INITIAL_STATE);
  const [hasPlayed, setHasPlayed] = React.useState(false);
  const playerRef = React.useRef<ReactPlayer>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const updateInterval = useMemo(() => {
    return (
      parseInt(process.env.REACT_APP_MEDIA_TRACK_INTERVAL_IN_MIN?.toString() ?? "2") *
      60 *
      1000
    );
  }, []);

  useEffect(() => {
    // We only want to control the state if the user clicks play
    if (activeVideoIndex === index && isPlaying) {
      dispatch({ type: 'PLAY' });
    } else {
      dispatch({ type: 'PAUSE' });
    }
  }, [activeVideoIndex, index, isPlaying]);

  const handlePreview = () => {
    dispatch({ type: 'PLAY' });
    dispatch({ type: 'LIGHT', payload: false });
    setHasPlayed(true);
    setCurrentTime((currentTime > 0 ? currentTime : 1));
    saveTrackingData(currentTime > 0 ? currentTime : 1);
  };

  const handlePause = () => {
    dispatch({ type: 'PAUSE' });
    setIsPlaying(false);
  };

  const handlePlay = () => {
    setActiveVideoIndex(index);
    dispatch({ type: 'PLAY' });
    setIsPlaying(!isPlaying);
    setHasPlayed(true);
  };

  const initializeUserModuleQuiz = (module: any) => {
    if (!module.userModuleQuiz) {
      module.userModuleQuiz = {
        id: 0,
        accountId: 0,
        locationId: 0,
        courseId: 0,
        moduleId: 0,
        userId: 0,
      };
    }

    if (!module.userModuleQuiz.mediaTrackingEventsJson) {
      module.userModuleQuiz.mediaTrackingEventsJson = {};
    }
  };

  const handleEnded = () => {
    dispatch({ type: 'LIGHT', payload: true });
    setIsPlaying(false);
    //setIsCompleted(true);
    setHasSeeked(true);
    playerRef.current?.showPreview();

    if (module) {
      initializeUserModuleQuiz(module);
      module.userModuleQuiz.mediaTrackingEventsJson = module.userModuleQuiz.mediaTrackingEventsJson ?? {};
      if (index === 0) {
        module.userModuleQuiz.mediaTrackingEventsJson.v1CompletedDate = new Date();
        module.userModuleQuiz.mediaTrackingEventsJson.v1SeekedToEnd = true;
      } else if (index === 1) {
        module.userModuleQuiz.mediaTrackingEventsJson.v2CompletedDate = new Date();
        module.userModuleQuiz.mediaTrackingEventsJson.v2SeekedToEnd = true;
      }
      saveTrackingData();
    }
  };

  const handleProgress = (progress: { playedSeconds: number }) => {
    dispatch({ type: 'SEEK', payload: progress.playedSeconds });
    const newTime = progress.playedSeconds;

    // Update currentTime state
    setCurrentTime(newTime);

    // Check if the difference is greater than the updateInterval
    const timeDifference = newTime - lastSeekPos;
    //console.log('current: ' + newTime + ' last Pos: ' + lastSeekPos + ' diff: ' + timeDifference);
    if (timeDifference * 1000 >= updateInterval) {
      //console.log(`Saving tracking data. timeDifference: ${timeDifference}s`);
      saveTrackingData(newTime); // Pass the current time for saving
    }
  };

  const handleDuration = (duration: number) => {
    dispatch({ type: 'DURATION', payload: duration });
  };

  const handleReady = () => {
    if (playerRef.current && !hasSeeked) {
      var seekPosition = index === 0
        ? module?.userModuleQuiz?.mediaTrackingEventsJson?.v1LastSeekPos ?? 0
        : module?.userModuleQuiz?.mediaTrackingEventsJson?.v2LastSeekPos ?? 0;

      if (module?.userModuleQuiz?.completedFlag) {seekPosition = 0;}

      playerRef.current.seekTo(seekPosition, 'seconds');
      dispatch({ type: 'PROGRESS', payload: seekPosition });
      setCurrentTime(seekPosition);
      setHasSeeked(true);
    }
  };

  const saveTrackingData = useCallback((seekTime?:number) => {
    if (!saveMediaTrackingEvents) return;

    const time = seekTime ?? currentTime ?? 0;
    //console.log('time: '+ time);

   if (module) {
      initializeUserModuleQuiz(module);

      module.userModuleQuiz.mediaTrackingEventsJson = module.userModuleQuiz.mediaTrackingEventsJson ?? {};
      if (index === 0) {
        module.userModuleQuiz.mediaTrackingEventsJson.v1LastSeekPos = time;
        module.userModuleQuiz.mediaTrackingEventsJson.v1TotalViewTime = (module.userModuleQuiz.mediaTrackingEventsJson.v1TotalViewTime || 0) + time;
      } else if (index === 1) {
        module.userModuleQuiz.mediaTrackingEventsJson.v2LastSeekPos = time;
        module.userModuleQuiz.mediaTrackingEventsJson.v2TotalViewTime = (module.userModuleQuiz.mediaTrackingEventsJson.v2TotalViewTime || 0) + time;
      }

      //console.log("Saving tracking data", module.userModuleQuiz.mediaTrackingEventsJson);
      saveMediaTrackingEvents(module.userModuleQuiz.mediaTrackingEventsJson);

      //console.log('updating last seekpos: ' + time);
      setLastSeekPos(time);
    }

  }, [saveMediaTrackingEvents, currentTime, index, module]);

  /*
  useEffect(() => {
    if (saveMediaTrackingEvents) {
      const interval = setInterval(() => {
          console.log('set interval is called...... is playing: ' + isPlaying);
            if (!isCompleted) {
              console.log('current time: ' + currentTime + ' last seek: ' + lastSeekPos + ' updated interval: ' + updateInterval);
              const timeDifference = currentTime - lastSeekPos;
              if (timeDifference * 1000 >= updateInterval) {
                console.log(
                  `Calling saveTrackingData: timeDifference (${timeDifference}s) >= updateInterval (${updateInterval / 1000}s)`
                );
                console.log('Calling saveTrackingData every ' + updateInterval + ' minutes');
                saveTrackingData();
              }
            }
        }, updateInterval);

        // Clean up the interval on component unmount or dependency change
        return () => clearInterval(interval);
    }
}, [isPlaying, hasPlayed, isCompleted, saveMediaTrackingEvents, saveTrackingData, updateInterval]);
*/
  const playerStyle: React.CSSProperties = {
    position: 'relative',
    aspectRatio: '16/9',
    borderRadius: '8px',
    overflow: 'hidden',
  };

  const controlsStyle: React.CSSProperties = {
    opacity: hasPlayed ? 1 : 0,
    transition: 'opacity 0.2s ease-in-out',
  };

  const overlayState: OverlayState = {
    light: state.light,
    playing: state.playing
  };

  const getVideoUrl = (videoKey: string) => {
    return module?.module.videos[videoKey].resolutions[0].videoUrl;
  }

  const getImage = (index: number) => {
    const mediaTwoCode = module?.module.mediaTwoCode;

    return mediaTwoCode && mediaTwoCode.toString().trim() !== ""
      ? `/assets/courses/v${index + 1}_cover.png`
      : "/assets/courses/v_cover.png";
  }

  const getStatus = (index: number) => {
    var status = "Not Started";
    //console.log(JSON.stringify(module?.userModuleQuiz));

    if (module?.userModuleQuiz?.completedFlag) {
      return "Completed";
    }

    if (!module?.userModuleQuiz?.mediaTrackingEventsJson) return status;

    if (index === 0) {
      if (module?.userModuleQuiz?.mediaTrackingEventsJson?.v1CompletedDate) {
        status = "Completed";
      } else if ((module?.userModuleQuiz?.mediaTrackingEventsJson?.v1TotalViewTime ?? 0) > 0) {
        status = `In Progress`;
      }
      else if (hasPlayed) { status = "In Progress";}
    } else {
      if (module?.userModuleQuiz?.mediaTrackingEventsJson?.v2CompletedDate) {
        status = "Completed";
      } else if ((module?.userModuleQuiz?.mediaTrackingEventsJson?.v2TotalViewTime ?? 0) > 0) {
        status = "In Progress";
      }
      else if (hasPlayed) { status = "In Progress";}
    }
    return `${status}`;
  }

  // Pause video when the browser tab becomes inactive
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handlePause();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      {module?.module?.isAudio && (
        <div className="lesson-course">
          <br />
          This is an audio-only module. Press play to listen.
        </div>
      )}

      {!module?.module?.isAudio && index === 0 && (
        <ExpandableSegment title="How to play a video?" content={
          <ol>
            <li>
              <b>Play Videos:</b> Click on a video to start playback.
            </li>
            <li>
              <b>Pause Control:</b> Pause a video by clicking the pause icon or anywhere on the video.
            </li>
            <li>
              <b>Volume:</b> Change volume of the video
            </li>
            <li>
              <b>Full Screen:</b> Double-click to enter full-screen mode, and double-click again to exit.
            </li>
          </ol>
        } />
      )}

      {!module?.module?.isAudio &&
        <h3>
          {getStatus(index) === "Completed" && (<Icon name="check" color="green" />)}
          {getStatus(index) === "In Progress" && (<Icon name="clock" color="blue" />)}
          {getStatus(index) === "Not Started" && (<Icon name="hand point right outline" color="grey" />)}
          Video {index + 1}: {getStatus(index)}
        </h3>
      }

      <div style={playerStyle} ref={wrapperRef}>
        <ReactPlayer
          ref={playerRef}
          url={getVideoUrl(videoKey)}
          width="100%"
          height="100%"
          light={getImage(index)}
          playIcon={
            <Icon
              name="play circle"
              size="massive"
              style={{
                color: 'white',
                fontSize: '6rem',
              }}
            />
          }
          controls={state.controls}
          loop={state.loop}
          muted={state.muted}
          playing={state.playing}
          playbackRate={state.playbackRate}
          volume={state.volume}
          onReady={handleReady}
          onPlay={handlePlay}
          onEnded={handleEnded}
          onPause={handlePause}
          onDuration={handleDuration}
          onProgress={handleProgress}
          onClickPreview={handlePreview}
        />
        {/* Show overlay only when paused or in light mode */}
        {!state.playing && <PlayerOverlay state={overlayState} />}

        {/* Show controls when the video has started playing */}
        <div style={controlsStyle}>
          <PlayerControls state={state} dispatch={dispatch} playerRef={playerRef} wrapperRef={wrapperRef} hasPlayed={hasPlayed}
           blockForward={typeof saveMediaTrackingEvents === 'function'}
          />
        </div>
      </div>
    </>
  );
};

export default LessonVideoPlayer;
