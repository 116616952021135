export enum ScheduleType {
    English_All = 1,
    English_Women = 2,
    Spanish_All = 3,
    Spanish_Women = 4
  }

  export enum WeekDay {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
  }

    export interface InterLiftSchedule{
    programName: string;
    weekBeginDate: Date;
    courses: ScheduleCourseDto[]
  }

  export enum ModulePart {
    FR1 = 1,
    FR2 = 2,
    FR3 = 3,
    FR4 = 4,
    SR1A = 5,
    SR1B = 6,
    SR2A = 7,
    SR2B = 8
  }

  export interface ScheduleCourseDto  {
    day: WeekDay;
    slot: string;
    courseName: string;
    moduleCode: string;
    moduleName: string
    modulePart?: ModulePart;
    scheduledCode: string;
    includeQuiz : boolean;
    includeActivity: boolean;
    includeGRMaterial: boolean;
  }